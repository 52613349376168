<template>
  <!-- 文件生成与下载组件 -->
  <div class="fileUpdate" ref="fileUpdate">
    <el-input v-bind="$attrs" v-on='$listeners' disabled style="flex:1"></el-input>
    <div class="row fl-16">
      <IconButton v-if="!hideGenerator" content='生成' icon='iconfont iconshengcheng' @click="generate"></IconButton>
       <el-upload
                action=""
                class="upload-demo"
                :http-request="uploadFile
                "
                :show-file-list="false"
                accept=".doc,.docx"
              >
                <IconButton
                  :content="fileId?'重新上传':'上传'"
                  :icon="fileId?'iconfont iconzhongxinshangchuan':'iconfont iconshangchuan'"
                ></IconButton>
              </el-upload>
      <IconButton content='下载' icon='iconfont iconxiazai' @click="downFile" v-if="fileId"></IconButton>
      <IconButton content='预览' icon='iconfont iconyulan' @click="preview" v-if="fileId"></IconButton>
      <IconButton content='删除' icon='iconfont iconshanchu1' @click="delFile" v-if="fileId"></IconButton>

    </div>
  </div>
</template>
<script>
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  name: 'fileUpdate',
  components: { IconButton },
  data () {
    return {
      value: '',
      fileId: ''
    }
  },
  props: {
    generatefileId: String,
    hideGenerator: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    generatefileId (val) {
      this.fileId = val
    }
  },
  methods: {
    // 预览
    preview () {
      this.$emit('preview', '')
    },
    // 生成
    generate () {
      this.$emit('generate', 'generate')
    },
    // 文件上传
    uploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      if (param.file.name.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const formData = new FormData()
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'docx']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传docx格式文件')
        return
      }
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.code === '0') {
          this.success('文件上传成功')
          this.value = res.data.fileName
          this.fileId = res.data.keyId
          this.$emit('input', res.data.fileName)
          this.$emit('upload', { fileId: res.data.keyId, fileName: res.data.fileName })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    // 文件下载
    downFile () {
      if (this.fileId) {
        const params = { keyId: this.fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 文件删除
    delFile () {
      this.$emit('delFile', '')
    }
  }
}
</script>
<style lang="scss" scoped>
.fileUpdate{
  display: flex;
  width: 100%;
}
.row{
  display: flex;
}
</style>
